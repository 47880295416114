import React, { useState,useEffect } from 'react'
import IlsMenu from './IlsMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import {AiOutlineMail,AiOutlineContacts,AiOutlineCalendar,AiOutlinePhone,AiOutlineEdit,AiOutlineCloudDownload,AiOutlineSend} from 'react-icons/ai';
import GoBack from '../../components/back/GoBack';
import {HiOutlineBuildingOffice} from 'react-icons/hi2';
import {RiBillLine} from 'react-icons/ri';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi';
import { CreateLeadAttachmentFollowUpService, CreateLeadNoteService, CreateLeadReminderService, GetLeadAttachmentFollowUpService, GetLeadNoteService, GetLeadNoteService1, GetLeadReminderService, GetLeadReminderService1, UpdateLeadAttachmentFollowUpService } from '../../services/LeadServices';
import { TextAreaInput1, TextInput } from '../../components/input';
import Uploader from '../../components/Uploader';
import { ButtonFilled, ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DatePicker, TimePicker, Modal } from 'antd';
import { LuAlarmClock } from "react-icons/lu";
import axios from 'axios';
import fileDownload from "js-file-download";
import {MdClose,MdOutlineMeetingRoom} from 'react-icons/md'
import { GetILSLandRequirmentDetailService } from '../../services/IlsLandRequirmentServices';
import DashboardMenu from '../dashboard/DashboardMenu';

function IlsLandRequirmentDetail() {
  
  const {state,pathname} = useLocation()
  let path = pathname.split('/')[2]
  const [step1,setstep1] = useState(1)
  const [data,setdata] = useState({})
  const [reminder_data,setreminder_data] = useState({visible:false,title:'',date:'',time:'',date1:'',time1:'',summary:''})
  const [reminder_data_err,setreminder_data_err] = useState({visible:false,title:'',date:'',time:'',summary:''})
  const [notes_data,setnotes_data] = useState({visible:false,title:'',summary:'',file:''})
  const [notes_data_err,setnotes_data_err] = useState({visible:false,title:'',time:'',summary:''})
  const [reminder,setreminder] = useState([]);
  const [notes,setnotes] = useState([]);
  const [followUps,setfollowUps] = useState([]);
  const [updateActivity,setupdateActivity] = useState(false)
  const [selected_activity,setselected_Activity] = useState({})
  const [selected_activity_error,setselected_Activity_error] = useState({summary:''})

  const [visible,setvisible] = useState(false)

  const [repeatProcess,setrepeatProcess] = useState({file:'',date:'',time:'',date1:'',time1:'',summary:''})
  const [repeatProcessErr,setrepeatProcessErr] = useState({file:'',date:'',time:'',summary:''})

  
  const navigate = useNavigate()

  useEffect(()=>{
    getreminders()
    getnotes()
    getfollowup()
    getdata()
  },[])

  async function getdata(){
    const response = await GetILSLandRequirmentDetailService(state._id)
    // console.log("response?.data",response?.data)
    setdata(response.data.datas[0])
  }

  async function getreminders(){
    const response = await GetLeadReminderService1(state._id)
    setreminder(response.data.datas)
  }

  async function getnotes(){
    const response = await GetLeadNoteService1(state._id)
    setnotes(response?.data?.datas)
  }

  

  const onChange = (e)=>{
    setreminder_data({...reminder_data,[e.target.name]:e.target.value})
    setreminder_data_err({...reminder_data_err,[e.target.name]:''})
  }

  const onChange1 = (e)=>{
    setnotes_data({...notes_data,[e.target.name]:e.target.value})
    setnotes_data_err({...notes_data_err,[e.target.name]:''})
  }

  const createreminder = async() => {
    if(reminder_data.title === ''){
      setreminder_data_err({title:'This field is required*'});
    }else if(reminder_data.date === ''){
      setreminder_data_err({date:'This field is required*'});
    }else if(reminder_data.time === ''){
      setreminder_data_err({time:'This field is required*'});
    }else{
      let send_data = {}
      send_data['title'] = reminder_data.title 
      send_data['summary'] = reminder_data.summary 
      send_data['type'] = 'ILS'
      send_data['type_id'] = state._id
      send_data['description'] = reminder_data.summary 
      send_data['repeat'] = "Once" 
      send_data['notify'] = "Email"
      

      // console.log("data krishna",reminder_data)

      if(data?.date !== ''){
        send_data['on_date_time'] =reminder_data?.date1 !== undefined &&  moment(reminder_data?.date1)?.format('YYYY-MM-DD') + ' ' + reminder_data?.time1
      }


      const response = await CreateLeadReminderService(send_data)
      // console.log("response",response)
      if(response.status === 200){
        toast.success("Reminder created successfully")
        setreminder_data({visible:false,title:'',date:'',time:'',summary:''})
        setvisible(false)
        setreminder_data_err({title:'',date:'',time:'',summary:''})
        getreminders()
      }
  }
  }

  const createnotes = async() => {
    if(notes_data.title === ''){
      setnotes_data_err({title:'This field is required*'});
    }else if(notes_data.summary === ''){
      setnotes_data_err({summary:'This field is required*'});
    }else{
      let fd = new FormData()

      fd.append('note_title',notes_data.title)  
      fd.append('summary',notes_data.summary)  
      fd.append('type_id',state._id)  
      fd.append('note_type','ILS')  

      console.log("notes_data.file",notes_data.file)

      if(notes_data.file !== ''){
        fd.append('file',notes_data?.file)
        fd.append('filesize',notes_data?.file?.size)
      }


      const response = await CreateLeadNoteService(fd)
      // console.log("response",response)
      if(response.status === 201){
        toast.success("Notes created successfully")
        setnotes_data({visible:false,title:'',summary:'',file:''})
        setvisible(false)
        setnotes_data_err({title:'',summary:'',file:''})
        getnotes()
      }
  }
  }

  const onChange2 = (e)=>{
    setselected_Activity({...selected_activity,[e.target.name]:e.target.value})
    setselected_Activity_error({...selected_activity_error,[e.target.name]:''})
  }

  function returnfilename(v){
    let string = v?.split("/")[v?.split("/")?.length - 1]
    return string;
  }

  function getFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);
    return Math.max(fileSizeInBytes, 0.1).toFixed(1).slice(0,4) + byteUnits[i];
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Attachment Downloaded") 
        })
  }

  async function getfollowup(){
    const response = await GetLeadAttachmentFollowUpService(state?._id)
    setfollowUps(response?.data?.datas)
  }

  const updateSummaryForActivity = async () => {
    if(!selected_activity.summary){
      setselected_Activity_error({...selected_activity_error,summary:'This Field is required *'})
    }else{
        let send_data1 = {}
        send_data1['_id'] = selected_activity?.id
        send_data1['attachment_type'] = 'Lead'
        send_data1['stage_type'] = selected_activity?.stage_type
        send_data1['stage'] = selected_activity?.stage
        send_data1['summary'] = selected_activity?.summary
        send_data1['finalized'] = selected_activity?.finalized === true ? 1 : 0
        send_data1['type_id'] = selected_activity?.type_id

        const response = await UpdateLeadAttachmentFollowUpService(send_data1,selected_activity?.id)
        if(response.status === 200){
          setselected_Activity({});
          setselected_Activity_error({})
          setupdateActivity(false)
          getfollowup()
          toast.success('Followup Summary Updated*')
        }
    }
  }

  const repeatProcessfunc = async () => {
    if(['Inspection',]?.includes(data.stage.name)){
      if(!repeatProcess.date){
        setrepeatProcessErr({...repeatProcessErr,date:'Date Field is required*'})
      }else if(!repeatProcess.time){
        setrepeatProcessErr({...repeatProcessErr,time:'Time Field is required*'})
      }else{
            // console.log("repeatProcess",repeatProcess)
            let send_data1 = {}
            send_data1['attachment_type'] = 'ILS'
            send_data1['stage_type'] = data?.stage?.name
            send_data1['stage'] = data?.stage?._id
            send_data1['summary'] = ''
            send_data1['finalized'] = 0
            send_data1['type_id'] = data?._id
            if(repeatProcess?.date1 !== '' && repeatProcess?.date1 !== undefined && repeatProcess?.date1 !== 'undefined'){
              send_data1['datetime'] =  repeatProcess?.date1 + ' ' +  repeatProcess?.time1
            }


            if(data?.file !== ''){
              send_data1['file'] = repeatProcess?.file
              send_data1['fileSize'] = repeatProcess?.file?.size 
            }
            const response = await CreateLeadAttachmentFollowUpService(send_data1)
            if(response.status === 201){
              getfollowup()
              setrepeatProcess({date:'',time:'',file:''})
              setrepeatProcessErr({date:'',time:'',file:''})
              toast.success("Follow up added successfully")
            }
      }
    }else{
      if(!repeatProcess.file){
        setrepeatProcessErr({...repeatProcessErr,file:'File Field is required*'})
      }else{
        let send_data1 = {}
            send_data1['attachment_type'] = 'ILS'
            send_data1['stage_type'] = data?.stage?.name
            send_data1['stage'] = data?.stage?._id
            send_data1['summary'] = ''
            send_data1['finalized'] = 0
            send_data1['type_id'] = data?._id
            if(repeatProcess?.date1 !== '' && repeatProcess?.date1 !== undefined && repeatProcess?.date1 !== 'undefined'){
              send_data1['datetime'] =  repeatProcess?.date1 + ' ' +  repeatProcess?.time1
            }
            if(data?.file !== ''){
              send_data1['file'] = repeatProcess?.file
              send_data1['fileSize'] = repeatProcess?.file?.size 
            }
        const response = await CreateLeadAttachmentFollowUpService(send_data1)
        if(response.status === 201){
          getfollowup()
          setrepeatProcess({date:'',time:'',file:''})
          setrepeatProcessErr({date:'',time:'',file:''})
          toast.success("Follow up added successfully")
        }
      }
    }

  }
  
  return (
    <div className='h-screen max-h-screen overflow-hidden'>
      <Modal  open={visible} footer={null} closable={false} width={300}>
         <div>
           
           {step1 === 2 &&
           <div>
              <h6 className='font-[800]'>Add Reminder</h6>
              <h6 className='text-[11px] leading-tight bg-slate-100 p-1'>Use the below form to create the reminder for the current leads</h6>

              <TextInput label="Title" name="title" value={reminder_data?.title} error={reminder_data_err?.title} handlechange={onChange} />
              <TextAreaInput1 label="Summary" name="summary" value={reminder_data?.summary}  error={reminder_data_err?.summary} handlechange={onChange} />
               
              <div className='flex'>
               <div>
               <h6 className='text-[11px] font-[600] mb-1' >{'Date'}</h6>
               <div className='border mr-1 border-slate-300'>
                 <DatePicker ampm={false}  inputFormat="MM/dd/yyyy" placeholder='' className='border-none ' value={reminder_data?.date} onChange={(v,v1) => {setreminder_data({...reminder_data,date:v,date1:v1});setreminder_data_err({...reminder_data_err,date:''})}} />
               </div>
               {(reminder_data_err?.date !== '' || reminder_data_err?.date  !== '')  && <h6 className='text-red-600 text-[10px]'>{reminder_data_err?.date}</h6>}
               </div> 

               <div>
               <h6 className='text-[11px] font-[600] mb-1' >{'Time'}</h6>
               <div className='border ml-1  border-slate-300'>
                 <TimePicker placeholder='' format="hh:mm"  ampm={false}  className='border-none' value={reminder_data?.time} onChange={(v,v1) => {setreminder_data({...reminder_data,time:v,time1:v1});setreminder_data_err({...reminder_data_err,time:''})}} />
               </div>
               {(reminder_data_err?.time !== '' || reminder_data_err?.time  !== '')  && <h6 className='text-red-600 text-[10px]'>{reminder_data_err?.time}</h6>}
               </div> 
               </div> 

              <div className='flex justify-end mt-3'>
                <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>{setvisible(false);setreminder_data({...reminder_data,visible:false})}} />
                <div className='ml-1'>
                <ButtonFilledAutoWidth btnName="Save" onClick={()=>createreminder()} />
                </div>
              </div>
           </div>} 

           {step1 === 3 &&
           <div>
              <h6 className='font-[800]'>Add Notes</h6>
              <h6 className='text-[11px] leading-tight bg-slate-100 p-1'>Use the below form to create the notes for the current leads</h6>

              <TextInput label="Title" name="title" value={notes_data?.title} error={notes_data_err?.title} handlechange={onChange1} />
              <TextAreaInput1 label="Summary" name="summary" value={notes_data?.summary}  error={notes_data_err?.summary} handlechange={onChange1} />
               
              <div>
               <h6 className='text-[11px] font-[600] mb-1' >{'Attachment'}</h6>
               <Uploader image={notes_data.file} setimagefunc={(v)=>setnotes_data({...notes_data,file:v})} removeimageuploadfunc={()=>setnotes_data({...notes_data,file:''})}  />  
              </div>

              <div className='flex justify-end mt-3'>
                <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>{setvisible(false);setnotes_data({...notes_data,visible:false})}} />
                <div className='ml-1'>
                <ButtonFilledAutoWidth btnName="Save" onClick={()=>createnotes()} />
                </div>
              </div>
           </div>}
          
         </div>

      </Modal>

      <div className='flex'>
            <div className='w-44 min-w-44 max-w-44'>
            {path === 'land_requirment' ?  <DashboardMenu /> : <IlsMenu />}
            </div>
            <div className='w-[88%] flex px-4 '>
            <div className='w-[25%] pt-5 overflow-scroll relative min-h-screen max-h-screen'>
                
                <div className='-mt-4'>
                <GoBack />
                </div>
                <div className='border-b-2 pb-4 border-slate-100'>
                <div className='grid place-items-center pt-4 -ml-4'>
                {/* <  */}
                {data?.stage?.name !== 'Invoice Raised' && <span onClick={()=>navigate(`/ils/${path}/edit`,{state:data})} className=' absolute top-1 right-0 text-slate-600 cursor-pointer font-[600] mr-2 px-1 py-1 text-[10px] flex items-center'><AiOutlineEdit size={13} className='mr-1.5'/>  {'Edit'}</span>}
                <h6 className='bg-purple-300 w-20 h-20 p-10 text-[22px] flex items-center justify-center uppercase rounded-full'>{data?.company_name?.slice(0,2)}</h6>
                <span className='text-[9.5px] font-[600] text-center capitalize'>{data?.company_name}</span>
                </div>

                <div className='grid grid-cols-3 ml-[12%] place-items-center w-[70%] mt-2'>

                <CopyToClipboard text={data?.email} onCopy={() => toast.success('Copied to clipboard')}>
                {/* <Tooltip title={state?.email}> */}
                <div className='grid place-items-center cursor-pointer'>
                <AiOutlineMail size={30} className='border  mx-1.5 rounded-full p-[7px] text-gray-700' />
                <span className='text-[9px] text-gray-800 mt-1 font-[600] '>Email</span>
                </div>
                {/* </Tooltip> */}
                </CopyToClipboard>

                <CopyToClipboard text={data?.phone} onCopy={() => toast.success('Copied to clipboard')}>
                {/* <Tooltip title={state?.phone}> */}
                <div className='grid place-items-center cursor-pointer'>
                <AiOutlinePhone size={30} className='border  mx-1.5 rounded-full p-[7px] text-gray-700' />
                <span className='text-[9px] text-gray-800 mt-1 font-[600] '>Phone</span>
                </div>
                {/* </Tooltip> */}
                </CopyToClipboard>

                <CopyToClipboard text={data?.industry?.name} onCopy={() => toast.success('Copied to clipboard')}>
                {/* <Tooltip title={state?.industry?.name}> */}
                <div className='grid place-items-center cursor-pointer'> 
                <HiOutlineBuildingOffice size={30} className='border  mx-1.5 rounded-full p-[7px] text-gray-700' />
                <span className='text-[9px] text-gray-800 mt-1 font-[600] '>Industry</span>
                </div>
                {/* </Tooltip> */}
                </CopyToClipboard>

                

                </div>
                </div>



                <div className='mt-2 w-[90%] ml-[3%]'>
                   <ButtonFilled btnName={JSON.stringify(data?.stage?.name)?.toUpperCase()?.slice(1,JSON.stringify(data?.stage?.name)?.length -1)} />
            </div>



                <h6 className='text-center text-[9px]  mt-2  '>Last Activity : <span className='font-[600]'> {data?.updatedAt?._d !== undefined ? moment(data?.updatedAt?._d)?.format('lll') :  moment(data?.updatedAt)?.format('lll')}</span></h6>



                <div className='mt-5'>
                {data?.point_of_contact_name &&
                <>
                <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Contact Name</h6>
                <h6 className='text-[12px] mb-0 font-[600] '>{data?.point_of_contact_name}</h6>
                </>}
                {data?.point_of_contact_mobile &&
                <>
                <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Contact Mobile</h6>
                <h6 className='text-[12px] mb-0 font-[600] '>{data?.point_of_contact_mobile}</h6>
                </>}
                {data?.point_of_contact_email &&
                <>
                <h6 className='text-[10px] mb-0 mt-2 font-[500] text-gray-400'>Contact Email</h6>
                <h6 className='text-[12px] mb-0 font-[600] '>{data?.point_of_contact_email}</h6>
                </>}
                
                {data?.requirment && <>
                <h6 className='text-[10px] mb-0 mt-3 font-[500] text-gray-400'>Requirment</h6>
                <h6 className='text-[12px] mb-0  font-[600] pr-2'>{data?.requirment}</h6>
                </>}
                </div>
            </div>
            <div className='w-[55%] pt-2 mb-10  px-2 overflow-scroll  bg-gray-50  min-h-screen max-h-screen'>
            <div className='flex w-[99.6%]  sticky  rounded justify-evenly px-[4px]  py-[5px]   bg-slate-200 ' >
                <h6 onClick={()=>setstep1(1)} className={`px-1.5 py-1.5 w-full text-center cursor-pointer font-[600] rounded text-[11px] ${step1 === 1 ? 'bg-white text-black font-[600]' : 'text-gray-500'}`}>Activity Done</h6>
                <h6 onClick={()=>setstep1(2)} className={`px-1.5 py-1.5 w-full text-center cursor-pointer font-[600] rounded text-[11px] ${step1 === 2 ? 'bg-white text-black font-[600]' : 'text-gray-500'}`}>Reminder Added</h6>
                <h6 onClick={()=>setstep1(3)} className={`px-1.5 py-1.5 w-full text-center cursor-pointer font-[600] rounded text-[11px] ${step1 === 3 ? 'bg-white text-black font-[600]' : 'text-gray-500'}`}>Notes Added </h6>
            </div>

            {step1 === 1 && 
            <div className='mx-2 z-20 relative'>
               {followUps?.map((f)=>(
                <div className='bg-white relative  my-5 rounded-md py-2 border border-slate-100'>
                <div className='flex border-b border-slate-100 px-2 py-1 justify-between'>
                <AiOutlineEdit size={20} className='absolute right-[145px] top-[14px] bg-slate-100 z-40 p-1 rounded-full' onClick={()=>{setselected_Activity(f);setupdateActivity(true)}} />
                <h6 className='flex items-center text-[13px] font-[600]'> <AiOutlineContacts className='bg-slate-100 p-1 mr-2' size={24}  /> {f?.stage_type} {f?.finalized  && <span className='ml-2 mt-1 text-[10px] font-[700]'>Finalized</span>}</h6>
                <h6 className='flex items-center text-[10px] font-[600] text-gray-400'><AiOutlineCalendar size={14} className='mr-2 text-slate-700'/> {f?.createdAt !== undefined && moment(f?.createdAt)?.format('lll')}</h6>
                </div>

                <div className='border m-3 p-3 rounded border-slate-200'>
                    <h6 className='text-[11px] pl-1'>Stage Type : <span className='font-[700]'>{f?.stage_type}</span></h6>
                    {f?.datetime !== undefined && <h6 className='text-[11px] pl-1'>{f?.stage_type === 'Inspection' && 'Inspection' } {f?.stage_type === 'Landlord Meeting' && 'Meeting' } {f?.stage_type === 'Client Meeting' && 'Client Meeting' }   Date Time : <span className='font-[700]'>{f?.datetime !== undefined && moment(f?.datetime)?.format('lll')}</span></h6>}
                    {(f?.file !== null && f?.file !== undefined && f?.file !== '') &&
                    <>
                      <div className='ml-1  flex relative py-1'>
                      <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 object-contain' />
                      <div className='ml-1 -mt-0.5'>
                      <h6 className='font-[600] text-[11px]'>{returnfilename(f?.file)}</h6>
                      <h6 className='font-[600] text-[9px] -mt-0.5'>{getFileSizeString(f?.fileSize)}</h6>
                      </div>
                      </div>
                   
                    
                    </>
                    }
                    <h6 className='text-[11px] pl-1 border-b pb-2'>Summary  : <span className='font-[700]'>{f?.summary === '' ? 'No Summary Added Yet' : f?.summary}</span></h6>

                    
                    <h6 className='text-[10px] my-1 ml-1'>Created By : <span className='font-[700]'>{f?.user_id?.name}</span> </h6>
                   
                </div>

                {/* <h6 className='text-[10px] ml-[1.5%] font-[800] -mt-[1%]'>{moment(f?.createdAt)?.format('LT')}</h6> */}

                </div>))}
               </div>}  

            {step1 === 2 && 
            <div className='mx-2 z-20 relative'>
              <span onClick={()=>{setvisible(true);setreminder_data({...reminder_data,visible:true})}} className='absolute right-0 -top-8 rounded  bg-slate-300 flex shadow text-gray-900 items-center p-1 text-[11px] font-[700] cursor-pointer'> <LuAlarmClock  size={16} className='mr-1' /> Add Reminder</span>
              <div className='grid grid-cols-3 gap-1 mt-10'>
                  {reminder?.map((r)=>(
                    <div className='bg-white p-2'>
                        <h6 className='text-[11px]'>Title : <span className='font-[600] text-[11px]'>{r?.title}</span> </h6>
                        <h6 className='text-[11px]'>Summary : <span className='font-[500] text-[11px]'>{r?.description}</span> </h6>
                        <h6 className='text-[11px] pb-2 border-b'>Date Time : <span className='font-[600] text-[11px]'>{r?.on_date_time !== undefined && moment(r?.on_date_time)?.format('lll')}</span> </h6>
                        <h6 className='text-[11px] mt-1.5 flex items-center z-50'><span className='bg-slate-100 p-2 h-5 flex rounded-full items-center font-[700]' >{r?.user_id?.name?.slice(0,1)}</span> <span className='font-[700] ml-1'>{r?.user_id?.name} / {r?.user_id?.employee_id}</span></h6>       
                    </div>  
                  ))} 
              </div>  

             {reminder?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any particual reminder added for this leads.</h6>
              </div>}
            </div>}

            {step1 === 3 && 
            <div className='mx-2 z-20 relative'>
              <span onClick={()=>{setvisible(true);setreminder_data({...reminder_data,visible:true})}} className='absolute right-0 -top-8 rounded  bg-slate-300 flex shadow text-gray-900 items-center p-1 text-[11px] font-[700] cursor-pointer'> <RiBillLine  size={16} className='mr-1' /> Add Notes</span>
             
              <div className='grid grid-cols-3 gap-1 mt-10'>
                  {notes?.map((r)=>(
                    <div className='bg-white p-2'>
                        <h6 className='text-[11px]'>Title : <span className='font-[600] text-[11px]'>{r?.note_title}</span> </h6>
                        <h6 className='text-[11px]'>Summary : <span className='font-[400] text-[11px]'>{r?.summary}</span> </h6>
                         {(r?.note_file !== null && r?.note_file !== '' && r?.note_file !== undefined) &&
                         <>
                         <div className='ml-1 mt-1 border-slate-100  flex relative py-1'>
                          <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 object-contain' />
                          <div className='ml-1 -mt-0.5'>
                          <h6 className='font-[600] text-[11px]'>{returnfilename(r?.note_file)}</h6>
                          <h6 className='font-[600] text-[9px] -mt-0.5'>{getFileSizeString(r?.filesize)} </h6>
                          </div>
                         {(r?.note_file !== null && r?.note_file !== undefined && r?.note_file !== '') && <AiOutlineCloudDownload size={23} className='absolute right-1 top-1.5 bg-slate-100 p-1 rounded-full' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${r?.note_file}`,returnfilename(r?.note_file))}/>}

                          </div>
                          </>}

                        <h6 className='text-[11px] mt-1.5 border-t pt-2 border-slate-100 flex items-center z-50'><span className='bg-slate-100 p-2 h-5 flex rounded-full items-center font-[700]' >{r?.user_id?.name?.slice(0,1)}</span> <span className='font-[700] ml-1'>{r?.user_id?.name} / {r?.user_id?.employee_id}</span></h6>       
                        <h6 className='text-[11px] mt-1.5 border-t pt-2 border-slate-100 flex items-center z-50'><span className='bg-slate-100 p-2 h-5 flex rounded-full items-center font-[700]' >Created At : </span> <span className='font-[700] ml-1'>{r?.createdAt !== undefined && moment(r?.createdAt)?.format('lll')}</span></h6>       
                    </div>  
                  ))} 
              </div>  

              {notes?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any particual notes added for this leads.</h6>
              </div>}

            </div>}

            {updateActivity &&
            <div className='w-[25%] z-50 shadow rounded-lg overflow-hidden  h-[30%] bg-white border fixed bottom-0 right-16'>
              <div className='flex items-center justify-between bg-slate-100 p-2 text-[13px] font-[500] text-black'> 
                <h6 className='font-[700]' >Update Activity Status</h6>
                <MdClose onClick={()=>setupdateActivity(false)} />
              </div>  

                    <div className='px-2'>
              
                        <TextAreaInput1 label="Summary" value={selected_activity?.summary}  name="summary" handlechange={onChange2} />

                        <div>
                          <span className='flex items-center text-[12px]'> {!selected_activity?.finalized ? <BiCheckbox onClick={()=>setselected_Activity({...selected_activity,finalized:!selected_activity.finalized})} className='text-slate-300 mr-1' size={20} /> :  <BiCheckboxSquare  onClick={()=>setselected_Activity({...selected_activity,finalized:!selected_activity.finalized})} className='text-slate-600 mr-1' size={20} /> } Finalized  </span>
                        </div>

                        <div className='flex justify-end mt-1'>
                        <h6 className={`flex ${selected_activity?.summary?.length > 3 ? 'opacity-100' : 'opacity-50'} cursor-pointer text-[11px] justify-center items-center px-2 py-2 w-[70px] text-white font-[500] bg-slate-700 p-1 rounded`} onClick={()=>updateSummaryForActivity()}>Send <AiOutlineSend className='ml-1'/></h6>
                        </div>
                      </div>

                    
            </div>}
            </div>    
            <div className='w-[24%]  bg-white p-2  min-h-screen max-h-screen'>
            {['Inspection','Proposal']?.includes(data?.stage?.name) &&
            <div>
             <h6 className='text-[13px] font-[700] mt-2'>Repeat Process {data?.stage?.name}</h6>
             <h6 className='bg-slate-100 mt-1 bg-salte-700 p-2 leading-tight text-[10px]'>Use the below form to update the repeated work made by you on the same stage. </h6> 
             
             <div className='mt-5'>
             {['Inspection',]?.includes(data?.stage?.name) &&
              <div className='flex'>
               <div>
               <h6 className='text-[11px] font-[600] mb-1' >{'Date'}</h6>
               <div className='border mr-1 border-slate-300'>
                 <DatePicker ampm={false}  inputFormat="MM/dd/yyyy" placeholder='' className='border-none ' value={repeatProcess.date} onChange={(v,v1) => {setrepeatProcess({...repeatProcess,date:v,date1:v1});setrepeatProcessErr({...repeatProcessErr,date:''})}} />
               </div>
               {(repeatProcessErr?.date !== '' || repeatProcessErr?.date  !== '')  && <h6 className='text-red-600 text-[10px]'>{repeatProcessErr?.date}</h6>}
               </div> 

               <div>
               <h6 className='text-[11px] font-[600] mb-1' >{'Time'}</h6>
               <div className='border ml-1  border-slate-300'>
                 <TimePicker placeholder='' format="hh:mm"  ampm={false}  className='border-none' value={repeatProcess.time} onChange={(v,v1) => {setrepeatProcess({...repeatProcess,time:v,time1:v1});setrepeatProcessErr({...repeatProcessErr,time:''})}} />
               </div>
               {(repeatProcessErr?.time !== '' || repeatProcessErr?.time  !== '')  && <h6 className='text-red-600 text-[10px]'>{repeatProcessErr?.time}</h6>}
               </div> 
              </div>} 


            {['Proposal']?.includes(data?.stage?.name) &&
             <>
              <h6 className='text-[11px] font-[600] mb-1'>Attachment</h6>
              <Uploader image={repeatProcess?.file}  setimagefunc={(e)=>{setrepeatProcess({...repeatProcess,file:e});setrepeatProcessErr({...repeatProcessErr,file:''})}}  removeimageuploadfunc = {()=>setrepeatProcess({...repeatProcess,file:''})} />
             </>}

             


              

             {/* <h6>{data?.stage}</h6> */}
            
             <div className='flex justify-end mt-2'>
                 <h6 onClick={()=>repeatProcessfunc()} className='flex items-center justify-center rounded text-[12px] bg-slate-600 cursor-pointer font-[600] px-2 py-1 text-white w-[80px]'>Send <AiOutlineSend className='ml-1' /></h6>
             </div>
            </div>
            </div>}
            {['Closure']?.includes(data?.stage?.name) &&
            <div>
            <div className='mt-4'>
              <h6 className='text-[10.5px]  leading-tight p-2 bg-blue-50'>Congrats you have reached the final stage of the <b>ILS</b> requirment now its time to focus on the other requirments.</h6>
           </div> 
            </div>}  
            </div>  
            </div>
      </div>  
      
        
    </div>
  )
}

export default IlsLandRequirmentDetail