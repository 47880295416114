import React, { useEffect, useState } from 'react'
import DashboardMenu from '../dashboard/DashboardMenu'
import { GetEmployeeShareBasedOnUserService, UpdateEmployeeShareBasedOnUserService } from '../../services/AdminServicesfile/TargetServices'
import { useLocation, useNavigate } from 'react-router-dom'
import {AiOutlineEdit,AiOutlineClose,} from 'react-icons/ai';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import ReactApexChart from 'react-apexcharts';
import { Modal, Select } from 'antd';
import { TextAreaInput1, TextInput } from '../../components/input';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import moment from 'moment';

function TeamTargetsIndivisualdetail() {

  const user = useSelector(state=>state.Auth)
  const {state} = useLocation()  
  const navigator = useNavigate()
  const [page,setpage] = useState(1)
  const [pagination,setpagination] = useState({})
  const [data,setdata] = useState([])
  const [incentiveAmount,setincentiveAmount] = useState(0)
  const [targetAchieved,settargetAchieved] = useState(0)
  const [modal,setmodal] = useState(false)
  const [selected_Data,setselected_Data] = useState({})
  const [selected_Data_Err,setselected_Data_Err] = useState({})
  const [period,setperiod] = useState([])
  const [selectedperiod,setselectedperiod] = useState({label:state?.period,value:state?.period})
  
  const [roles_arr,setroles_arr] = useState([])
  const [graph_data,setgraph_data] = useState({})
  const [all_data,setall_data] = useState(0)

  const [chartOptions1,setchartOptions1] = useState({
    series: [],

   chart: {
    toolbar: {
      show: false
    }
   },
    plotOptions: {
      
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        
      },
    },
    dataLabels: {
      enabled: false
    },
    grid:{
      show: true,
      borderColor: '#fafafa',
    },
  
    stroke: {
      // show: true,
      width: 1,
      
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Target', 'Threshold', 'Revenue', 'Incentive'],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return  val
        }
      }
    },
    fill:['#027cd1']
  })


  // console.log("state",state) 

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });


  useEffect(()=>{
    setselectedperiod({label:state?.period,value:state?.period})
    getdata()
  },[])  

  // useEffect(()=>{
  //   getdata()
  // },[page])

  async function getdata(){
    try{
        const response =  await GetEmployeeShareBasedOnUserService(page,state?.user?._id,selectedperiod?.value !== undefined ? selectedperiod?.value : '')
        setall_data(response?.data?.datas)
        setdata(response?.data?.datas?.datas)
        setpagination(response?.data?.pagination)

        let d1 = response?.data?.datas?.graph_data



        setroles_arr(d1?.roles_arr)
        setgraph_data(d1)

          let periods = []
          for(let i=0;i<state?.total_period;i++){
            periods.push({label:i+1,value:i+1})
          }
          setselectedperiod({label:d1?.current_period,value:d1?.current_period})
          setperiod(periods)


        settargetAchieved(d1?.revenue)
        setincentiveAmount(d1?.incentive)




        let series = [
            {
                name:'amount',
                data: [
                     d1?.current_target ,
                     Math.round(d1?.current_threshold),
                     Math.round(d1?.revenue),
                     (d1?.roles_arr?.includes('hod') ||  d1?.roles_arr?.includes('manager')) ?  (Math.round(d1?.incentive) +  Math.round(d1?.team_member_incentive_amount)) + Math.round(graph_data?.parked_amount !== null ? graph_data?.parked_amount : 0) : Math.round(d1?.incentive) +  Math.round(graph_data?.parked_amount !== null ? graph_data?.parked_amount : 0)
                 ]
            }
        ]
            
        setchartOptions1({...chartOptions1,series:[...series]})
    }catch(err){

    }
  } 

  console.log("all_data",all_data)


  function resetfunc(){
    setpage(1)
    setmodal(false)
    setselected_Data({})
  }

  function handleChange(e){
     setselected_Data({...selected_Data,[e.target.name]:e.target.value})
     setselected_Data_Err({...selected_Data_Err,[e.target.name]:''})
  }

  async function updateData(){
    if((user?.roles?.includes('manager') || user?.roles?.includes('bd_user'))  && selected_Data?.incentive_percentage >  all_data?.maximum_percentage){
      setselected_Data_Err({...selected_Data_Err,incentive_percentage:`The  Percentage Should  between [0-${all_data?.maximum_percentage}] *`})
    }else if(!selected_Data.modified_remarks){
      setselected_Data_Err({...selected_Data_Err,modified_remarks:'This Field is required *'})
    }else{
      let updated_data = {
        incentive_percentage:selected_Data.incentive_percentage,
        modifed:selected_Data.modifed,
        modified_remarks:selected_Data.modified_remarks,
        modified_by:user.id
      }

      if(user?.roles?.includes('admin')){
        updated_data['incentive_amount'] = selected_Data.incentive_amount
        updated_data['eligible_for_incentive'] = selected_Data.eligible_for_incentive
        updated_data['quarter'] = selected_Data.quarter
      } 

      try{
        const response = await UpdateEmployeeShareBasedOnUserService(selected_Data?._id,updated_data)
        if(response.status === 200){
          toast.success(response?.data?.data)
          setselected_Data({})
          setmodal(false)
          getdata()
        }
      }catch(err){
      }
    }
  }

  async function setselectedperiodfun(e){
    let period_selected = period?.find((v)=>v.value === e)
    setselectedperiod({...period_selected})
    try{
      const response =  await GetEmployeeShareBasedOnUserService(1,state?.user?._id,period_selected?.value,1)

      setdata(response?.data?.datas?.datas)
      setpagination(response?.data?.pagination)

      let d1 = response?.data?.datas?.graph_data

      setroles_arr(d1?.roles_arr)
      setgraph_data(d1)

      // if(d1.current_period === 1){
      //   setperiod([{label:1,value:1}])
      //   setselectedperiod({label:1,value:1})
      // }else{
      //   let periods = []
      //   for(let i=0;i<d1?.current_period;i++){
      //     periods.push({label:i+1,value:i+1})
      //   }
      //   setselectedperiod({label:d1?.current_period,value:d1?.current_period})
      //   setperiod(periods)
      // }

      let periods = []
      for(let i=0;i<state?.total_period;i++){
        periods.push({label:i+1,value:i+1})
      }
      // console.log("periods kp",periods)
      setselectedperiod({label:d1?.current_period,value:d1?.current_period})
      setperiod(periods)
      

      settargetAchieved(d1?.revenue)
      setincentiveAmount(d1?.incentive)




      let series = [
          {
              name:'amount',
              data: [
                   d1?.current_target ,
                   Math.round(d1?.current_threshold),
                   Math.round(d1?.revenue),
                   (d1?.roles_arr?.includes('hod') ||  d1?.roles_arr?.includes('manager')) ?  (Math.round(d1?.incentive) +  Math.round(d1?.team_member_incentive_amount)) : Math.round(d1?.incentive)
               ]
          }
      ]
          
      setchartOptions1({...chartOptions1,series:[...series]})
  }catch(err){

  }
  }

  // async function deleteEmployeeShare(id){
  //   const response = await DeleteEmployeeShare(id)
  //   if(response?.status === 200){
  //     toast?.success("Deleted Successfully")
  //     getdata()
  //   }
  // }


  //krishna modified

  // console.log("user",user?.roles)

  function returnAmtwithTdsDeduction(){
   return Math.round(((graph_data?.team_member_incentive_amount + incentiveAmount + (graph_data?.parked_amount !== null ? graph_data?.parked_amount : 0))/(100)) * (100 - (graph_data?.insentiverequirment?.tds)))
  }
 

  return (
    <div className='min-h-screen h-screen max-h-screen overflow-hidden'>
      <Modal open={modal} closable={false} footer={false} width={300} className='rounded-[0px] z-50' >
         
          <AiOutlineClose size={13} className='absolute right-2 top-2 cursor-pointer z-90' onClick={()=>setmodal(false)} />

          <h6 className='text-[13px] leading-tight bg-slate-100 p-2 '>Use the below form to update the percentage of the user for their incentive</h6>

            <TextInput label="Incentive Percentage" name="incentive_percentage" value={selected_Data?.incentive_percentage} error={selected_Data_Err?.incentive_percentage} handlechange={handleChange} />
           
            {user?.roles?.includes('admin') &&
            <>
            <TextInput label="Quarter" name="quarter" value={selected_Data?.quarter} error={selected_Data_Err?.quarter} handlechange={handleChange} />
            <TextInput label="Incentive Amount" name="incentive_amount" value={selected_Data?.incentive_amount} error={selected_Data_Err?.incentive_amount} handlechange={handleChange} />
 
            <div className='flex items-center mt-1' onClick={()=>setselected_Data({...selected_Data,eligible_for_incentive:!selected_Data
            .eligible_for_incentive})}>
             {!selected_Data?.eligible_for_incentive ?  <BiCheckbox size={18} className='text-slate-300' /> :  <BiCheckboxSquare size={18} className='text-slate-700' />}
              <h6 className='text-[11px] ml-1' >Incentive Eligible</h6>
            </div>
            </>
            }

            <div className='flex items-center mt-1' onClick={()=>setselected_Data({...selected_Data,modifed:!selected_Data
            .modifed})}>
             {!selected_Data?.modifed ?  <BiCheckbox size={18} className='text-slate-300' /> :  <BiCheckboxSquare size={18} className='text-slate-700' />}
              <h6 className='text-[11px] ml-1' >Modify</h6>
            </div>

            {selected_Data?.modifed &&
            <TextAreaInput1 label="Modify Remarks" name="modified_remarks" error={selected_Data_Err?.modified_remarks} value={selected_Data?.modified_remarks} handlechange={handleChange}  />
            }

            {selected_Data?.modifed &&
            <div className='flex mt-2'>
              <ButtonFilledAutoWidth btnName="Save" onClick={updateData} />
              <h6 className='w-[10px]'></h6>
              <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
            </div>}
      </Modal>
       
        <div className='flex'>
                <DashboardMenu />
                <div className='w-[80vw] flex'>
                    <div className='border-r min-w-[70%] w-[70%] max-w-[70%] '>
                        <div className='w-full'>
                        <div className='flex items-center justify-between mx-2 mt-2'>
                        <h6 className='font-[700] text-[13px] '>Payment Share ({pagination?.total})</h6>

                        <div className='flex items-center'>
                        <div className='flex items-center'>
                        <h6 className='mr-2 font-[600] text-[12px]'>{page == 1 ? data?.length > 0 ? 1 : 0 : (page - 1) * pagination?.limit } - {pagination?.limit} of {pagination?.total} </h6>
                        <IconButton  onClick={resetfunc} ><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(pagination?.totalPages === page || data?.length === 0) ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        </div>
                        <div>
                          {/* <Select options={[{label:'2023',value:'2023'},{label:'2024',value:'2024'}]} size='small' placeholder="Quarter" className='border rounded-md w-[75px] mr-2' bordered={false} /> */}
                          <Select value={selectedperiod} options={period} onChange={(e)=>setselectedperiodfun(e)} size='small' placeholder="Quarter" className='border rounded-md w-[50px]' bordered={false} />
                        </div>
      
                        </div>
                        </div> 
                        </div>    



                        <div className='h-[93vh] overflow-y-scroll'>
                        <div className='flex text-[13px] mx-2 mt-2 border border-slate-200'>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[27%] w-[27%] max-w-[27%]'>Lead Info</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[16%] w-[16%] max-w-[16%]'>Amount</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[16%] w-[16%] max-w-[16%]'>Incentive Amt</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[12%] w-[12%] max-w-[12%]'>Days</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[12%] w-[12%] max-w-[12%]'>Eligible</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[7%] w-[7%] max-w-[7%]'>%</h6>
                            <h6 className='px-2 py-1 font-[600] '>Action</h6>
                        </div>
                        
                        {data?.map((d)=>(
                        <div key={d?._id} className={`flex text-[12px] mx-2 border-b border-l border-r border-slate-200 ${d?.is_deleted ? 'bg-slate-100 opacity-100' : ''}`}>
                            <h6 className='px-2 py-1 border-r border-slate-200  min-w-[27%] w-[27%] max-w-[27%]'>{d?.client?.contact_name} / {d?.client?.phone} <br></br> <p className='text-[11px]'>Company Name : <span className='font-[700]'>{d?.client?.company_name}</span></p><span className='text-[10px] font-[700]'>{moment(d?.createdAt)?.format('LLL')}</span> <p onClick={()=>navigator('/leads/contacts/detail',{state:d?.client?._id})} className='text-[9px] cursor-pointer font-[700] text-sky-900 underline'>View Detail</p></h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[700]  min-w-[16%] w-[16%] max-w-[16%]'>{rupee?.format(d?.amount).split('.')[0]}</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[700] min-w-[16%] w-[16%] max-w-[16%]'>{rupee?.format(d?.incentive_amount).split('.')[0]}</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200  min-w-[12%] w-[12%] max-w-[12%]'>{d?.days_took_for_payment_recieve}</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200  min-w-[12%] w-[12%] max-w-[12%]'>{d?.eligible_for_incentive ?  <BiCheckboxSquare size={20} className='text-slate-700' /> : <BiCheckbox className='text-slate-300' size={20}/>}</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200  min-w-[7%] w-[7%] max-w-[7%]'>{!d?.is_deleted && d?.incentive_percentage} </h6>
                            <h6 className={`px-2 py-1 ${!user?.roles?.includes('admin') ? 'cursor-not-allowed' :''} `}>{(!d?.is_deleted && (parseInt(selectedperiod?.value) == parseInt(d.quarter)) && user?.roles?.includes('admin') ) && <AiOutlineEdit className='cursor-pointer' onClick={()=>{user?.roles?.includes('admin') && setmodal(true);setselected_Data(d)}}  />}  
                            {/* <AiOutlineDelete onClick={()=>deleteEmployeeShare(d?._id)} className='cursor-pointer' /> */}
                            </h6>
                        </div>
                        ))}
                        </div>
                        </div>
                    </div>   

                    <div className='min-w-[30%] h-screen overflow-y-scroll  m-2 w-[30%] max-w-[30%] '>
                        <h1 className='text-[13px] font-[700] w-[96%] mt-5 ml-2 bg-slate-100 px-2 py-1'>{`${state?.user?.name}`} Report</h1>



                        <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'}  height={240} />

                        
                        <div className='ml-2 border-t border-slate-100'>
                       
                        {/* <div className='flex bg-slate-100 p-2 justify-between mb-2 mt-4 border-b pb-2 border-slate-100'>
                        <div >
                        <h6 className='text-[13px] '>Current Target</h6>
                        <h6 className='text-[12px] font-[700]'>{rupee?.format((state?.target / 4)*state?.period)?.split('.')[0]}</h6>
                        </div>  

                        <div >
                        <h6 className='text-[13px] '>Target Achieved</h6>
                        <h6 className='text-[12px] font-[700]'>{rupee?.format(targetAchieved)?.split('.')[0]}</h6>
                        </div>  
                        </div> */}

                        
                        <div className='mx-2 flex justify-between border-t border-slate-100'>


                        <div className='mb-2 '>
                        <h6 className='text-[13px] '>Your Incentive </h6>
                        <h6 className='text-[12px] font-[700]'>{rupee?.format(Math.round(incentiveAmount))?.split('.')[0]}</h6>
                        </div> 

                        {/* {!(roles_arr?.includes('hod') && !roles_arr?.includes('manager')) &&
                        <div className='mb-2 pl-2 mt-1 border-t pb-2 border-slate-100'>
                        <h6 className='text-[13px] '>Total Incentive (TDS Deduction)</h6>
                        <h6 className='text-[12px] font-[700]'>{rupee?.format(returnAmtwithTdsDeduction())?.split('.')[0]}</h6>
                        </div>} */}

                        

                        {(roles_arr?.includes('hod') || roles_arr?.includes('manager')) &&  
                        <>
                        <div className='mb-2 '>
                        <h6 className='text-[13px] '>Team Incentive </h6>
                        <h6 className='text-[12px] font-[700]'>{rupee?.format(Math.round(graph_data?.team_member_incentive_amount))?.split('.')[0]}</h6>
                        </div>

                        {roles_arr?.includes('manager') &&
                        <div className='mb-2 '>
                        <h6 className='text-[13px] '>Parked Amount </h6>
                        <h6 className='text-[12px] font-[700]'>{rupee?.format(Math.round(graph_data?.parked_amount !== null ? graph_data?.parked_amount : 0))?.split('.')[0]}</h6>
                        </div>}
                        </>} 
                        </div>

                        {(roles_arr?.includes('hod') || roles_arr?.includes('manager')) &&  
                        <>
                        <div className='mb-2 pl-2 mt-1 border-t pb-2 border-slate-100'>
                        <h6 className='text-[13px] '>Total Incentive </h6>
                        <h6 className='text-[12px] font-[700]'>{rupee?.format(Math.round(graph_data?.team_member_incentive_amount + incentiveAmount + (graph_data?.parked_amount !== null ? graph_data?.parked_amount : 0)))?.split('.')[0]}</h6>
                        </div> 

                        <div className='mb-2 pl-2 mt-1 border-t pb-2 border-slate-100'>
                        <h6 className='text-[13px] '>Total Incentive (TDS Deduction)</h6>
                        <h6 className='text-[12px] font-[700]'>{rupee?.format(returnAmtwithTdsDeduction())?.split('.')[0]}</h6>
                        </div> 


                        {graph_data?.team_member_incentive?.length === 0 &&
                        <>
                         <h6 className='bg-slate-100 p-2 text-[11px] leading-tight'>We Found that your team members not achieved any incentive to share you motivate them to reach there targets to get incentive.</h6>
                        </>} 

                        {graph_data?.team_member_incentive?.length > 0 && 
                        <>
                        <div className='flex border'>
                           <h6 className='text-[11px] p-1 border-r  min-w-[24%] w-[24%] max-w-[24%]'>Employee</h6>
                           <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>Target</h6>
                           <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>Threshold</h6>
                           <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>Achieved</h6>
                           <h6 className='text-[11px] p-1  min-w-[19%] w-[19%] max-w-[19%]'>Incentive</h6>
                        </div> 
                        {graph_data?.team_member_incentive?.map((d)=>(
                        <div key={d?.employee?._id} className='flex border-l border-b border-r'>
                           <h6 className='text-[11px] truncate p-1 border-r  min-w-[24%] w-[24%] max-w-[24%]'>{d?.employee?.name}</h6>
                           <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>{rupee?.format(Math.round(d?.vertical_target))?.split('.')[0]}</h6>
                           <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>{rupee?.format(Math.round(d?.threshold_target))?.split('.')[0]}</h6>
                           <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>{rupee?.format(Math.round(d?.target_achieved))?.split('.')[0]}</h6>
                           <h6 className='text-[11px] p-1  min-w-[19%] w-[19%] max-w-[19%] font-[700]'>{rupee?.format(Math.round(d?.incentive))?.split('.')[0]}</h6>
                        </div> 
                        ))}
                        </>}

                        </>}
                        
                        </div>
                        
                    </div> 

                </div>
        </div>
    </div>
  )
}

export default TeamTargetsIndivisualdetail