import React, { useEffect, useState } from 'react'
import IlsMenu from './IlsMenu'
import { IconButton } from '@mui/material'
import { DatePicker, Select } from 'antd'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { useNavigate } from 'react-router-dom'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DeleteILSLandDataService, GetILSLandDataService } from '../../services/IlsLandDataServices'
import moment from 'moment'
import {Modal} from 'antd'
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

function IlsLandData() {

  const user = useSelector(state=>state.Auth)

  const [data,setdata] = useState({datas:[],pagination:{total:0,limit:25}})  
  const [page,setpage] = useState(1)
  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,zone:'',type_of_transaction:''})
  const [selectedData,setselectedData] = useState({})
  const [modal,setmodal] = useState(false)

  const zones = [{label:'North',value:"North"},{label:'South',value:"South"},{label:'East',value:"East"},{label:'West',value:"West"}]
  const type_of_transactions = [{label:'Built-To-Suit',value:"Built-To-Suit"},{label:'Sale',value:"Sale"},{label:'Rent',value:"Rent"}]
  


  const navigate = useNavigate()


  useEffect(()=>{
    getData()
  },[page])

  async function getData(){
    const response = await GetILSLandDataService(page,search?.text,search?.from_date1,search.to_date1,search.zone,search.type_of_transaction)
    setdata(response?.data)
  }

  async function applyfilterfunction(){
    setpage(1)
    getData()
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,zone:'',type_of_transaction:''})
    setpage(1)
    const response = await GetILSLandDataService(1,'','','','','')
    setdata(response?.data)
  }

  async function deleteDatafunc(){
    const response = await DeleteILSLandDataService(selectedData?._id)
    if(response?.status == 200){
      toast.success("Deleted Successfully")
      getData()
      setmodal(false)
    }
  }

  function openfilefunc(v){
    let url = `${process.env.REACT_APP_BACKEND_IMAGE_URL}${v?.replace('public/','')}`
    window.open(url,'_blank')
  }


  return (
    <div className='h-screen max-h-screen overflow-hidden'>
       <Modal open={modal} className='absolute top-0 left-[41%]' width={320} closable={false} footer={false}>
          <div> 
            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before Deleting the data be sure that it is required or not for further purpose.</h6>

            <h6 className='text-[11px] mt-1 mb-2'>Land Info : <span className='font-[700]'>{selectedData?.land_area}</span> / <span className='font-[400]'>{selectedData?.location}</span></h6>
            
            <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>

          </div>
        </Modal>
        
        <div className='flex'>
        <IlsMenu />
        <div className='w-[88%] px-4 mt-5'>
              <div>
              <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Land Data ({data?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>
                   

                        <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                        <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                    </div>


                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                    
                    <div className='mr-2 flex'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                     
                    <div className='mt-0'>
                    <Select 
                     bordered={false}
                     placeholder="Zone"
                     value={search?.zone === '' ? null : search?.zone}
                     options={zones}
                     onChange={(e)=>setsearch({...search,zone:e})}
                     className='w-[80px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                    />

          
                    </div>  

                    <div className='mt-0'>
                    <Select 
                     bordered={false}
                     value={search?.type_of_transaction === '' ? null : search?.type_of_transaction}
                     placeholder="Transaction Type"
                     options={type_of_transactions}
                     onChange={(e)=>setsearch({...search,type_of_transaction:e})}
                     className='w-[80px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                    />
                    </div> 
                    </div>    

                  
                <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
               
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
                </div>
                    </div>
                </div>
                
                {data?.datas?.length === 0 &&
                <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                </div>
                }

                {data?.datas?.length > 0 &&
                <div className='border-l overflow-y-scroll max-h-[90vh] border-r'>
                  <div className='flex border-b sticky top-0 bg-white'>
                    <h6 className='text-[12px] py-1 px-2 border-r w-[70px] font-[600]'>Sl No</h6>
                    <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Land Area </h6>
                    <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Location</h6>
                    <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Zone</h6>
                    <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Transaction Type</h6>
                    <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Contact Name</h6>
                    <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Contact Mobile</h6>
                    <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Contact Email</h6>
                    <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Created At</h6>
                    <h6 className='text-[12px] py-1 px-2 w-[10%] font-[600]'>Action</h6>
                  </div>  
                 {data?.datas?.map((d,i)=>(
                  <div className='flex border-b'>
                    <h6 className='text-[11px] py-1 px-2 border-r w-[70px] font-[500]'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                    <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[500]'>{d?.land_area} <br></br> {![undefined,null,'']?.includes(d?.attachment) && <h6 onClick={()=>openfilefunc(d?.attachment)} className='underline font-[700] text-slate-700 cursor-pointer'>View File</h6>}</h6>
                    <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500]'>{d?.location}</h6>
                    <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[500]'>{d?.zone}</h6>
                    <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500]'>{d?.type_of_transaction}</h6>
                    <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[500]'>{d?.point_of_contact_name}</h6>
                    <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[500]'>{d?.point_of_contact_mobile}</h6>
                    <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500]'>{d?.point_of_contact_email}</h6>
                    <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[500]'>{moment(d?.createdAt)?.format('ll')}</h6>
                    <h6 className='text-[11px] flex py-1 px-2 w-[10%] font-[500]'>
                    {/* <RiBillLine size={13} className='mr-2' onClick={()=>navigate('detail',{state:d})} />  */}
                    <AiOutlineEdit size={13} className='mr-2' onClick={()=>navigate('edit',{state:d})} /> 
                    {(user?.roles?.includes('delete_data') || user?.roles?.includes('ils_hod') || user?.roles?.includes('hod')) &&
                    <AiOutlineDelete size={13} onClick={()=>{setselectedData(d);setmodal(true)}} /> 
                    }
                    </h6>
                  </div>  
                  ))}

                </div>  
                }
              </div>
        </div>
        </div>

    </div>
  )
}

export default IlsLandData