import React,{useState,useEffect} from 'react'
import { AiOutlineClose } from "react-icons/ai";
import CalendarMenu from './CalendarMenu';
import { ButtonFilledAutoWidth } from '../../components/button';
import ErrorComponent from '../../components/errorDesign/ErrorComponent';
import {MdOutlineDescription,MdOutlineLocationOn,MdOutlineGroup} from 'react-icons/md';
import {AiOutlineClockCircle,AiOutlineDownload} from 'react-icons/ai';
import {Drawer, Select} from 'antd'
import { GetSearchService } from '../../services/AuthServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { LuUserSquare2 } from "react-icons/lu";
import { TbBrandGoogleDrive,TbNotes } from "react-icons/tb";
import { CreateCalendarService, GetCalendarFollowupService, UpdateCalendarService, UploadCalendarService } from '../../services/CalendarServices';
import axios from 'axios';
import fileDownload from "js-file-download";
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';

function CalendarCE() {

  
  const {state,pathname} = useLocation()  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]

  const navigator = useNavigate()  

  const [data,setdata] = useState({reminderTo:[],remarks:'',title:'',description:'',location:'',photos:[],time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
  const [error,seterror] = useState({reminderTo:'',remarks:'',title:'',description:'',location:'',photos:'',time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
    

  const [followUp,setfollowUp] = useState(false)
  const [users,setusers] = useState([])
  const [calendarFollowUp,setcalendarFollowUp] = useState({})
  const [departments,setdepartments] = useState([])

  useEffect(()=>{
    if(state !== null){
        datamodaldata(state)
        if(!['',null,undefined,'null','undefined']?.includes(state?._id)){
           getfollowupTracker(state?._id)
        }
    }
    getdeparmtent()
  },[])

  async function  getdeparmtent() {
    const response = await  GetDepartmentService()
    let arr = []
    let d = response?.data?.datas 
    d?.forEach((d1)=>{
        arr?.push({label:d1?.department_name,value:d1?.id})
    })
    setdepartments(arr)
  }


  async function handleSelect(v) {
    let arr  = []
    v?.forEach((d)=>{
        if(users.find((f)=>f?.value == d) !== null){
            arr?.push(users.find((f)=>f?.value == d)) 
        }else{
            if(data?.reminderTo.find((f)=>f?.value == d) !== null){
                arr?.push(data?.reminderTo.find((f)=>f?.value == d)) 
            }
        }
    })

        
        setdata({...data,reminderTo:arr})
    }

    async function searchuser(v) {
        const response = await GetSearchService(v,1)
        let arr = []
        let d = response?.data?.datas
        d?.forEach((d1)=>{
            arr?.push({label:d1?.name,value:d1?._id})
        })
        setusers(arr)
    }


  async function submit() {
    if(!data.title){
        seterror({...error,title:'This Field is required *'})
    }else if(!data.date1){
        seterror({...error,date1:'This Field is required *'})
    }else if(!data.time){
        seterror({...error,time:'This Field is required *'})
    }else{
        

        if(path == 'create'){
                let sendData = {...data}
                sendData['date'] = parseInt(data?.date1?.slice(8,10))
                sendData['year'] = parseInt(data?.date1?.slice(0,4))
                sendData['month'] = parseInt(data?.date1?.slice(5,7)) - 1

                if(data?.date1 !== '' && data?.time !== ''){
                    sendData['date_time'] = data?.date1 !== undefined && data?.date1 + ' ' + data?.time
                }

                if(data?.reminderTo?.length > 0){
                    let arr = []
                    data?.reminderTo?.forEach((d1)=>{
                        arr?.push(d1?.value)
                    })
                    sendData['reminderTo'] = arr

                }else{
                    delete sendData?.reminderTo
                }

                if(data?.department !== ''){
                    sendData['department'] = data?.department?.value
                }else{
                    delete sendData?.department
                }


                if(!['',null,undefined,'null','undefined']?.includes(data?.followup_calendar)){
                    sendData['followup_calendar'] = state.followup_calendar
                }

                delete sendData?._id

            const response = await CreateCalendarService(sendData)
            if(response?.status === 201){
                toast.success("Calendar Created Successfully!")
                resetform()
            }
        }else{
            let sendData = {...data}
            sendData['date'] = parseInt(data?.date2?.slice(8,10))
            sendData['year'] = parseInt(data?.date2?.slice(0,4))
            sendData['month'] = parseInt(data?.date2?.slice(5,7)) - 1

            if(data?.date1 !== '' && data?.time !== ''){
                sendData['date_time'] = data?.date1 !== undefined && data?.date1 + ' ' + data?.time
            }
      
            if(data?.reminderTo?.length > 0){
                let arr = []
                data?.reminderTo?.forEach((d1)=>{
                    arr?.push(d1?.value)
                })
                sendData['reminderTo'] = arr

            }else{
                delete sendData?.reminderTo
            }

            if(data?.department !== ''){
                sendData['department'] = data?.department?.value
            }else{
                delete sendData?.department
            }

            const response = await UpdateCalendarService(sendData,data?._id)
            if(response?.status === 200){
                toast.success("Calendar Updated Successfully!")
                resetform()
            }
        }
    }
  }  

  function resetform(){
    setdata({reminderTo:[],title:'',remarks:'',description:'',location:'',photos:[],time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
    seterror({reminderTo:'',title:'',remarks:'',description:'',location:'',photos:'',time:'',date:'',year:'',date_time:'',type:'',date1:'',time:'',date2:'',time1:'',department:''})
  }

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function uploadFile(v) {
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadCalendarService(fd)
    if(response?.status === 200){
      let datas = [...data.photos] 
      datas.push(response?.data?.data)
      setdata({...data,photos:datas})
      seterror({...error,photos:''})
    }
  }

  const handleDownload = (url, filename) => {
    axios.get(`${process.env.REACT_APP_AWS_IMAGE_URL}${url}`, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }

  function removeimage(i){
    let photos = [...data?.photos]
    photos.splice(i,1)
    setdata({...data,photos:photos})
  }


  function datamodaldata(d){
  console.log("d here",d)

    if(d?._id === undefined){
        setdata(d)
    }else{
        let sendData = {...d}
        // let arr = []
        // d?.reminderTo?.forEach((d1)=>{
        //     arr?.push({label:d1?.name,value:d1?._id})
        // })
        // sendData['reminderTo'] = arr

        // if(d?.department !== ''){
        //     sendData['department'] =  {label:d?.department?.department_name,value:d?.department?._id}
        // }else{
        //     sendData['department'] = ''
        // }

        // if(d?.date_time !== '' && d?.date_time !== null && d?.date_time !== undefined){
        //     sendData['date1'] = d?.date_time?.slice(0,10)
        //     sendData['date2'] = d?.date_time?.slice(0,10)
        //     sendData['time'] = moment(d?.date_time?.slice(11,16), "HH:mm").add(5, 'hours').add(30, 'minutes').format("HH:mm");
        //     sendData['time1'] =  moment(d?.date_time?.slice(11,16), "HH:mm").add(5, 'hours').add(30, 'minutes').format("HH:mm"); 
        // }else{
        //     sendData['date1'] = ''
        //     sendData['date2'] = ''
        //     sendData['time'] = ''
        //     sendData['time1'] = ''
        // }

        setdata(sendData)
    }
  }


  async function getfollowupTracker(id) {
     const response = await GetCalendarFollowupService(id)
     setcalendarFollowUp(response?.data?.data[0])
  }


  return (
    <div className='h-screen relative flex w-[100%] max-h-screen '>
        <Drawer open={followUp} width={'25%'} placement='right' closable={false}>
          <div className='relative'>
            <AiOutlineClose  onClick={()=>setfollowUp(false)} className='absolute cursor-pointer right-0' />
            <h6 className='font-[800] text-[15px]'>Calendar Followup Report!</h6>

            <div>
                <h6 className='text-[12px] bg-slate-100 p-1 font-[900] mt-4'>Calendar Main Info</h6>

                <div className='border-t border-b border-slate-200  py-1 my-2'>
                    <h6 className='text-[13px] font-[700]'>{calendarFollowUp?.calendar?.title}</h6>
                    {calendarFollowUp?.calendar?.description !== '' &&
                    <h6 className='text-[12px] bg-slate-100 p-1 my-1'>Description : {calendarFollowUp?.calendar?.description}</h6>}
                    {calendarFollowUp?.calendar?.remarks !== '' &&
                    <h6 className='text-[12px]'>Remarks : {calendarFollowUp?.calendar?.remarks}</h6>}
                    <h6 className='text-[12px]'>{moment(calendarFollowUp?.calendar?.date_time)?.format('LLL')}</h6>
                    <h6 className='text-[12px]'>Type : <span className='font-[700]'>{calendarFollowUp?.calendar?.type}</span> / Calendar Type : <span className='font-[700]'>{calendarFollowUp?.calendar?.calendar_type}</span></h6>
                    
                    {calendarFollowUp?.calendar?.department?.department_name !== undefined && <h6 className='text-[12px]'>Department : <span className='font-[700]'>{calendarFollowUp?.calendar?.department?.department_name}</span></h6>}
                   
                    {calendarFollowUp?.calendar?.department?.reminderTo?.length > 0 && 
                    <div className='mt-2'>
                        <h6 className='text-[11px]'>Reminder To</h6>
                        <div className='flex flex-wrap'>
                            {calendarFollowUp?.calendar?.reminderTo?.map((p,i)=>(
                            <div key={p} className='px-2 rounded w-max flex items-center py-1 mr-1 mb-1 bg-blue-100'> 
                                <h6 className='text-[11px]'>{p?.name}</h6>
                            </div>
                            ))}
                        </div>
                    </div>}

                    {calendarFollowUp?.calendar?.photos?.length > 0 &&
                    <div className='mt-4'>
                    <h6 className='text-[11px]'>File</h6>
                    {calendarFollowUp?.calendar?.photos?.map((p,i)=>(
                    <div key={p} className='px-2 rounded w-max flex items-center py-1 mr-1 mb-1 bg-blue-100'> 
                        <h6 className='text-[11px]'>{p?.split('/')[p?.split('/')?.length - 1]?.slice(0,10)}</h6>
                        <AiOutlineDownload onClick={()=>handleDownload(p,p?.split('/')[p?.split('/')?.length - 1])} size={12} className='ml-2' />
                        <AiOutlineClose onClick={()=>removeimage(i)} size={12} className='ml-2' />
                    </div>
                   ))}
                    </div>}
                </div>


                <h6 className='text-[12px] bg-slate-100 p-1 font-[900] mt-4'>Follow Up Info ({calendarFollowUp?.calendar_followup?.length})</h6>

                {calendarFollowUp?.calendar_followup?.map((c)=>(
                <div key={c} className='border-t border-slate-200  py-1 my-2'>
                    <h6 className='text-[13px] font-[700]'>{c?.title}</h6>
                    {c?.description !== '' &&
                    <h6 className='text-[12px] bg-slate-100 p-1 my-1'>Description : {c?.description}</h6>}
                    {c?.remarks !== '' &&
                    <h6 className='text-[12px]'>Remarks : {c?.remarks}</h6>}
                    <h6 className='text-[12px]'>{moment(c?.date_time)?.format('LLL')}</h6>
                    <h6 className='text-[12px]'>Type : <span className='font-[700]'>{c?.type}</span> / Calendar Type : <span className='font-[700]'>{c?.calendar_type}</span></h6>
                    
                    {c?.department?.department_name !== undefined && <h6 className='text-[12px]'>Department : <span className='font-[700]'>{c?.department?.department_name}</span></h6>}
                   
                    {c?.department?.reminderTo?.length > 0 && 
                    <div className='mt-2'>
                        <h6 className='text-[11px]'>Reminder To</h6>
                        <div className='flex flex-wrap'>
                            {c?.reminderTo?.map((p,i)=>(
                            <div key={p} className='px-2 rounded w-max flex items-center py-1 mr-1 mb-1 bg-blue-100'> 
                                <h6 className='text-[11px]'>{p?.name}</h6>
                            </div>
                            ))}
                        </div>
                    </div>}

                    {c?.photos?.length > 0 &&
                    <div className='mt-4'>
                    <h6 className='text-[11px]'>File</h6>
                    {c?.photos?.map((p,i)=>(
                    <div key={p} className='px-2 rounded w-max flex items-center py-1 mr-1 mb-1 bg-blue-100'> 
                        <h6 className='text-[11px]'>{p?.split('/')[p?.split('/')?.length - 1]?.slice(0,10)}</h6>
                        <AiOutlineDownload onClick={()=>handleDownload(p,p?.split('/')[p?.split('/')?.length - 1])} size={12} className='ml-2' />
                        <AiOutlineClose onClick={()=>removeimage(i)} size={12} className='ml-2' />
                    </div>
                   ))}
                    </div>}
                </div>))}
            </div>
          </div>
        </Drawer>



       <div className='flex w-[100%] '>
        <CalendarMenu /> 
        <div className='w-[87%]'>
            <div className='px-4 min-w-[60%] max-w-[60%] my-3'>
            
            <div className='flex items-center'>
            <AiOutlineClose onClick={()=>navigator(-1)} size={25} className='text-gray-800 mt-3 cursor-pointer' />

            <div className='w-[100%] mx-2 mr-6 -mt-1'>
            <input name="title" value={data?.title} onChange={handleChange} className=' w-[100%] mt-4 focus:outline-none  text-[21px] p-1 placeholder:text-[16px] border-b' type='text' placeholder='Add title' />
            <ErrorComponent error={error?.title} />
            </div>
             
            <div className='mt-3 flex items-center'>
            <ButtonFilledAutoWidth onClick={submit} btnName="Save" />
            {(data?._id !== undefined && calendarFollowUp?._id !== undefined) && 
            <h6 onClick={()=>setfollowUp(true)} className='mx-2 cursor-pointer bg-slate-200 w-max text-[12px] font-[600] rounded p-1.5 px-2' >View FollowUp</h6>
            } 
            </div>

            </div>




            </div>

            <div className='flex px-4 min-w-[60%] max-w-[60%] '>
                <div className='w-[65%] pr-5 border-r'>


                <div className='flex mt-2 ml-6'>
                    <span onClick={()=>{setdata({...data,type:'Event'})}} className={`border cursor-pointer ${data?.type == 'Event' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Event</span>
                    <span onClick={()=>{setdata({...data,type:'Out of office'})}} className={`border cursor-pointer ${data?.type == 'Out of office' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Out of office</span>
                    <span onClick={()=>{setdata({...data,type:'Task'})}} className={`border cursor-pointer ${data?.type == 'Task' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Task</span>
                    <span onClick={()=>{setdata({...data,type:'Meeting'})}} className={`border cursor-pointer ${data?.type == 'Meeting' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Meeting</span>
                    <span onClick={()=>{setdata({...data,type:'Calls'})}} className={`border cursor-pointer ${data?.type == 'Calls' && 'bg-blue-200 border-blue-200 text-blue-600'} text-[12px] py-1 px-1.5 rounded mr-2`}>Calls</span>
                </div>

               {(data?.calendar_type !== undefined && data?.calendar_type !== null && data?.calendar_type !== '') && <h6 className='mt-3 ml-6 text-[11px]'>Calendar Type : <span className='bg-green-100 font-[800] rounded px-2 text-green-800 p-1'>{data?.calendar_type}</span></h6>}


               <div className='flex  my-1 mt-4 items-center'>
                <MdOutlineGroup className='text-gray-500' size={16} />
                <button className='ml-2 hover:bg-slate-100 rounded p-1 text-[12px] px-2 cursor-pointer'>Select Department</button>
                </div>

                <div className='ml-[7%]'>
                    <Select 
                    placeholder="Select department if applicable"
                    bordered={false}
                    value={data?.department == '' ? null : data?.department}
                    filterOption={false}
                    showSearch
                    onChange={(v)=>setdata({...data,department:departments?.find((f)=>f?.value == v)})}
                    options={departments}
                    showArrow={false}
                    className='bg-slate-100 w-[100%] '
                    />
                </div> 
                
               <div className='flex  my-1 mt-4 items-center'>
                    <AiOutlineClockCircle className='text-gray-500' size={18} />

                    <div className='ml-3 w-[100%] flex'>
                    <div className='w-[100%]'>
                    <div className='w-[100%] bg-slate-100 border-slate-300'>
                    <input type='date' className='bg-slate-100 text-[13px] p-1 w-[100%] outline-none' width={'100%'} placeholder={'Select Date'} bordered={false}  ampm={false} sx={{height:'40px'}} inputFormat="MM/dd/yyyy" value={data?.date1} onChange={(e) => {setdata({...data,date1:e.target.value});seterror({...error,date1:''})}} />
                    <ErrorComponent error={error?.date1} />
                    </div>
                    </div>
                    <p style={{width:10}}></p>
                    <div className='w-[100%]'>
                    <div  className=' w-[100%]  border-slate-300'>
                    <input type='time' className='bg-slate-100 text-[13px] p-1 w-[100%] outline-none' width={'100%'} placeholder={'Select Time'} bordered={false} format="hh:mm" ampm={false} sx={{height:'40px'}} value={data?.time} onChange={(e) => {setdata({...data,time:e.target.value});seterror({...error,time:''})}} />
                    <ErrorComponent error={error?.time} />
                    </div>
                    </div>
                    </div>
                </div>

                <div className='flex  my-1 mt-4 '>
                <MdOutlineLocationOn className='text-gray-500' size={20} />
                <input name="location" value={data?.location} onChange={handleChange} className='bg-slate-100 ml-2 text-[13px] w-[100%] focus:outline-none  p-1 px-2 placeholder:text-[13px]' type='text' placeholder='Add location' />
               </div>

                <div className='flex  my-1 mt-4 '>
                <MdOutlineDescription className='text-gray-500' size={20} />
                <textarea name="description" value={data?.description} onChange={handleChange} className='bg-slate-100 ml-2 text-[13px] w-[100%] focus:outline-none  p-1 px-2 h-[200px] placeholder:text-[13px]' type='text' placeholder='Add description' />
                </div>


            <div className='flex  my-1 mt-4 '>
                <TbBrandGoogleDrive className='text-gray-500' size={20} />

                <form onClick={()=>{document.querySelector('.input-field').click()}} >
                  <h6 className='ml-2 hover:bg-slate-100 rounded p-1 text-[12px] px-2 cursor-pointer'>Upload File
                    <input type='file' onChange={({target:{files}})=>{
                    files[0] && uploadFile(files[0])
                    }} accept="*" className='input-field' hidden />
                  </h6>
               </form> 

            </div>  
            <div className='ml-[8%] flex flex-wrap mb-1.5'>
                {data?.photos?.map((p,i)=>(
                    <div key={p} className='px-2 rounded w-max flex items-center py-1 mr-1 mb-1 bg-blue-100'> 
                        <h6 className='text-[11px]'>{p?.split('/')[p?.split('/')?.length - 1]?.slice(0,10)}</h6>
                        <AiOutlineDownload onClick={()=>handleDownload(p,p?.split('/')[p?.split('/')?.length - 1])} size={12} className='ml-2' />
                        <AiOutlineClose onClick={()=>removeimage(i)} size={12} className='ml-2' />
                    </div>
                ))}
            </div>  

            <div className='flex  my-1 my-4 '>
                <TbNotes size={20} className='text-gray-500' />
                <textarea name="remarks" value={data?.remarks} onChange={handleChange} className='bg-slate-100 ml-2 w-[100%] text-[13px] focus:outline-none  p-1 px-2 h-[120px] placeholder:text-[13px]' type='text' placeholder='Add remarks' />
                </div>



                </div>

                <div className='w-[35%]'>

                <div className='flex ml-[8%] my-1 mt-4 items-center'>
                    <LuUserSquare2 className='text-gray-500' size={16} />
                    <button className='ml-2 hover:bg-slate-100 rounded p-1 text-[12px] px-2 cursor-pointer'>Add guests</button>
                </div>

                <div className='ml-[8%] mt-2'>
                    <Select 
                    placeholder="Search to add guest"
                    bordered={false}
                    mode='multiple'
                    value={data?.reminderTo}
                    filterOption={false}
                    showSearch
                    onSearch={searchuser}
                    onChange={handleSelect}
                    options={users}
                    showArrow={false}
                    className='bg-slate-100 w-[100%] '
                    />
                </div> 


                </div>

            </div>
        </div>   
        </div> 

    </div>
  )
}

export default CalendarCE